import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { CheckIcon } from "@heroicons/react/solid"
import { ribbon } from "./pricing.module.css"
import Faq from "../components/faq"

const PricingsPage = () => (
  <Layout>
    <SEO title="Pricing" />
    <div className="container max-w-full mx-auto py-6 px-6">
      <h1 className="text-center text-4xl text-black font-medium leading-snug tracking-wider">
        Pricing
      </h1>

      <div className="max-w-full md:max-w-6xl mx-auto my-3 md:px-8">
        <div className="relative block flex flex-col md:flex-row items-center justify-center">
          <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-mr-4">
            <div className="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden">
              <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                <h1 className="text-2xl font-medium uppercase p-3 pb-0 text-center tracking-wide">
                  Lite
                </h1>
                <h2 className="text-sm text-center">
                  <span className="text-5xl">$59</span> / month
                </h2>
                <h2 className="text-gray-700 text-center pb-6">
                  billed annually or $99 billed month-to-month
                </h2>
              </div>

              <div className="flex flex-wrap mt-3 px-6">
                <ul>
                  <li className="flex items-center">
                    <div className="rounded-full p-2 fill-current">
                      <CheckIcon className="w-6 h-6 text-primary" />
                    </div>

                    <span className="text-gray-700 text-lg ml-2">
                      One user account
                    </span>
                  </li>
                  <li className="flex items-center">
                    <div className="rounded-full p-2 fill-current">
                      <CheckIcon className="w-6 h-6 text-primary" />
                    </div>

                    <span className="text-gray-700 text-lg ml-2">
                      All modules
                    </span>
                  </li>
                </ul>
              </div>
              <div className="block flex items-center p-8 uppercase">
                <Link
                  to="/register/"
                  className="mt-3 text-lg font-semibold w-full rounded-lg uppercase text-center px-6 py-3 block hover:bg-primary hover:text-white border"
                >
                  Start free trial
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full max-w-md sm:w-2/3 lg:w-1/3 sm:my-5 my-8 relative z-10 bg-white rounded-lg shadow-lg">
            <div className={ribbon}>
              <span>Popular</span>
            </div>

            <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
              <h1 className="text-2xl font-medium uppercase p-3 pb-0 text-center tracking-wide">
                Standard
              </h1>
              <h2 className="text-sm text-green-500 text-center">
                <span className="text-5xl">$99</span> / month
              </h2>
              <h2 className="text-gray-700 text-center pb-6">
                billed annually or $139 billed month-to-month
              </h2>
            </div>
            <div className="flex pl-12 justify-start sm:justify-start mt-3">
              <ul>
                <li className="flex items-center">
                  <div className="rounded-full p-2 fill-current">
                    <CheckIcon className="w-6 h-6 text-primary" />
                  </div>
                  <span className="text-gray-700 font-bold text-lg ml-2">
                    All lite features
                  </span>
                </li>
                <li className="flex items-center">
                  <div className="rounded-full p-2 fill-current">
                    <CheckIcon className="w-6 h-6 text-primary" />
                  </div>
                  <span className="text-gray-700 text-lg ml-2">
                    Multiple user accounts (up to 5)
                  </span>
                </li>
                <li className="flex items-center">
                  <div className="rounded-full p-2 fill-current">
                    <CheckIcon className="w-6 h-6 text-primary" />
                  </div>
                  <span className="text-gray-700 text-lg ml-2">
                    Interactive container mode
                  </span>
                </li>
                <li className="flex items-center">
                  <div className="rounded-full p-2 fill-current">
                    <CheckIcon className="w-6 h-6 text-primary" />
                  </div>
                  <span className="text-gray-700 text-lg ml-2">
                    Share load plans by URL
                  </span>
                </li>
              </ul>
            </div>

            <div className="block flex items-center p-8 uppercase">
              <Link
                to="/register/"
                className="mt-3 text-lg font-semibold bg-primary w-full text-white rounded-lg text-center px-6 py-3 block shadow-xl hover:bg-gray-700 uppercase"
              >
                Start my trial
              </Link>
            </div>
          </div>
          <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-ml-4">
            <div className="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden">
              <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                <h1 className="text-2xl font-medium uppercase p-3 pb-0 text-center tracking-wide">
                  Enterprise
                </h1>
                <h2 className="text-sm text-gray-500 text-center pb-6">-</h2>
              </div>
              <div className="flex flex-wrap mt-3 px-6">
                <ul>
                  <li className="flex items-center">
                    <div className="rounded-full p-2 fill-current">
                      <CheckIcon className="w-6 h-6 text-primary" />
                    </div>
                    <span className="text-gray-700 text-lg ml-2">
                      API access
                    </span>
                  </li>
                  <li className="flex items-center">
                    <div className="rounded-full p-2 fill-current">
                      <CheckIcon className="w-6 h-6 text-primary" />
                    </div>
                    <span className="text-gray-700 text-lg ml-2">
                      Single Sign On
                    </span>
                  </li>
                  <li className="flex items-center">
                    <div className="rounded-full p-2 fill-current">
                      <CheckIcon className="w-6 h-6 text-primary" />
                    </div>
                    <span className="text-gray-700 text-lg ml-2">
                      Audit trails
                    </span>
                  </li>
                  <li className="flex items-center">
                    <div className="rounded-full p-2 fill-current">
                      <CheckIcon className="w-6 h-6 text-primary" />
                    </div>
                    <span className="text-gray-700 text-lg ml-2">
                      On-premise solution
                    </span>
                  </li>
                  <li className="flex items-center">
                    <div className="rounded-full p-2 fill-current">
                      <CheckIcon className="w-6 h-6 text-primary" />
                    </div>
                    <span className="text-gray-700 text-lg ml-2">SLA</span>
                  </li>
                  <li className="flex items-center">
                    <div className="rounded-full p-2 fill-current">
                      <CheckIcon className="w-6 h-6 text-primary" />
                    </div>
                    <span className="text-gray-700 text-lg ml-2">
                      Great discounts for multiple branches
                    </span>
                  </li>
                </ul>
              </div>

              <div className="block flex items-center p-8 uppercase">
                <a
                  href="/contact"
                  className="mt-3 text-lg font-semibold w-full rounded-lg uppercase text-center px-6 py-3 block hover:bg-primary hover:text-white border"
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
        <Faq
          title="Frequently Asked Questions"
          questions={[
            {
              q: "What payment methods do you offer?",
              a: {
                __html:
                  "We accept both credit cards and bank transfers by invoice",
              },
            },
            {
              q: "Can I cancel my subscription at any time?",
              a: {
                __html:
                  "Yes, you can cancel your subscription at any time. However we will not refund any payments",
              },
            },
            {
              q: "Can I share my login?",
              a: {
                __html:
                  "Not for the Lite package, however for the Standard package you can share your login within the same site / office / location",
              },
            },
            {
              q: "Is Cargo-Planner free for educational purposes?",
              a: {
                __html:
                  "Yes, Cargo-Planner is completely free when used for educational purposes. Contact us and ask for a free educational account <a href='/contact' class='text-primary hover:text-primarydark'>here</a>.",
              },
            },
          ]}
        />
      </div>
    </div>
  </Layout>
)

export default PricingsPage
